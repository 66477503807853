<template>
    <div class=container v-if="loaded">
        <p class=MainTitle>Recent Press</p>
        <div class=item v-for="item in rp" :key="item">
            <a class=url :href="item.url">{{ item.text }}</a>
            <p class=info>{{ item.source }}</p>
        </div>
    </div>
</template>

<script setup>
import rp from '@/assets/press/press.json';
const loaded = rp.length !== 0;
</script>

<style scoped>
.container {
    height: fit-content;
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    font-size: 18px;
}

.MainTitle {
    font-family: 'Raleway', sans-serif;
    font-size: 32px;
    width: 100%;
    text-align: left;
    font-weight: 600;
}

.info {
    margin-top: 0.4em;
    margin-bottom: 1.8em;
}

.url {
    color: black;
    text-decoration: none;
    font-weight: 600;
}

.url:hover {
    color: #a5a2a2;
}
</style>